import { Button, Card, Col, DatePicker, Form, Row, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE, DEFAULT_DATE_FORMAT } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";

function HotoReport() {
  const columns = [
    {
      title: "Site ID",
      dataIndex: "site_id",
    },
    {
      title: "RA",
      dataIndex: "raName",
    },
    {
      title: "Takeover Date",
      dataIndex: "takeover_date",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
  ];
  let reportHeaders = [
    ["Takeover Date", "RA Name", "Site Id", "City", "State"],
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/hoto",
    null,
    API_METHODS.POST
  );
  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();

  const [getCityResponse, getCityRequest] = useApi(
    "/admin/reports/city",
    null,
    API_METHODS.GET
  );
  const [getStateResponse, getStateRequest] = useApi(
    "/admin/reports/state",
    null,
    API_METHODS.GET
  );
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data?.length > 0) {
      data = replaceEmpty(data);
    }
    setTableData(data);
  }, [responseData]);

  useEffect(() => {
    getCityRequest();
    getStateRequest();
  }, []);

  useEffect(() => {
    const data =
      getCityResponse.data !== BLANK_VALUE && getCityResponse.data != null
        ? getCityResponse.data
        : [];
    setCity(data);
  }, [getCityResponse]);

  useEffect(() => {
    const data =
      getStateResponse.data !== BLANK_VALUE && getStateResponse.data != null
        ? getStateResponse.data
        : [];
    setState(data);
  }, [getStateResponse]);

  const getReportData = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.date) {
          values.date = dayjs(values.date).format("YYYY-MM-DD");
        }
        getRequest(values);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card title="Takeover Form Report">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="date" label={<strong>For Date</strong>}>
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="city" label={<strong>City</strong>}>
              <Select
                placeholder="Select City"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {city.map((option) => (
                  <Select.Option key={option.city} value={option.city}>
                    {option.city}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="state" label={<strong>State</strong>}>
              <Select
                placeholder="Select State"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {state.map((option) => (
                  <Select.Option key={option.state} value={option.state}>
                    {option.state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getReportData}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "Takeover Form Report-" +
          form.getFieldValue("date")?.format("DD-MM-YYYY")
        }
      />
    </Card>
  );
}

export default HotoReport;
