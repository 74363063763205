import React, { useEffect, useState } from "react";
import TableComponent from "../table/TableComponent";
import useApi from "../../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../../constants";

function TempData() {
  const columns = [
    {
      title: "Supervisor Name",
      dataIndex: "display_name",
    },
    {
      title: "Total Visits",
      dataIndex: "total_visits",
    },
    {
      title: "Completed Visits",
      dataIndex: "completed_visits",
    },
  ];
  const [responseData, getRequest] = useApi(
    `/images/tempdata`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
  }, [responseData]);

  return responseData.isLoading ? (
    <h2>Please wait</h2>
  ) : (
    <TableComponent
      columns={columns}
      tableData={tableData}
      loading={responseData.isLoading}
    />
  );
}

export default TempData;
